module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Guillaume Mourier","short_name":"Guillaume Mourier","start_url":"/","background_color":"#fff","theme_color":"#334259","display":"standalone","icon":"src/images/favicon-32x32.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c4cacb89fd3bf1cabb58ea5fbffe3087"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-169833719-1","head":false,"anonymize":true,"respectDNT":true,"exclude":["/contactSuccess"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
